<template>
  <v-app style=" background-color: #FAFAFA;">
    <Toolbar />
    <v-main>
      <v-container fluid grid-list-xs pt-6>
        <v-layout row wrap>
          <v-flex xs10 lg10>
            <div>
              <h3>
                <v-avatar color="#f7b733" class="mr-5">
                  <v-icon dark>mdi-monitor-lock</v-icon>
                </v-avatar>
                รายการรับชำระที่ไม่พบข้อมูล
              </h3>
            </div>
          </v-flex>
        </v-layout>
        <br />
        <v-form ref="form_searchdata" lazy-validation>
          <!-- เงื่อนไขการค้นหาข้อมูล -->
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 lg11 pl-4 pr-4 pb-2>
              <v-card class="rounded-lg">
                <v-card-text>
                  <h3>เงื่อนไขการค้นหา</h3>
                </v-card-text>
                <v-layout row wrap justify-center align-center pa-5>
                  <v-flex xs12 lg3 pa-4>
                    <!-- เงื่อนไขการค้นหา วันที่รับชำระ -->
                    <v-dialog
                      ref="dialogFromdate"
                      v-model="modalFromdate"
                      :return-value.sync="fromdate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          color="amber accent-4"
                          label="วันที่รับชำระ "
                          v-model="fromdate"
                          @click:prepend="modalFromdate = true"
                          prepend-icon="mdi-calendar-outline"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        scrollable
                        locale="th"
                        color="amber accent-4"
                        @input="fn_changeFromdate()"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalFromdate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- เงื่อนไขการค้นหา ชื่อลูกค้า -->
                  <v-flex xs12 lg3 pa-4>
                    <v-dialog
                      ref="dialogTodate"
                      v-model="modalTodate"
                      :return-value.sync="todate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          color="amber accent-4"
                          label="ถึง"
                          v-model="todate"
                          prepend-icon="mdi-calendar-outline"
                          @click:prepend="modalTodate = true"
                          v-on="on"
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        scrollable
                        locale="th"
                        color="amber accent-4"
                        @input="$refs.dialogTodate.save(todate)"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalTodate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-account-box-outline"
                      label="ชื่อลูกค้า"
                      color="amber accent-4"
                      v-model="customer_name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-badge-account-horizontal-outline"
                      label="Customer Tax ID"
                      color="amber accent-4"
                      v-model="customer_taxID"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-list-outline"
                      label="Invoice Number"
                      color="amber accent-4"
                      v-model="invoice_number_from"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-list-outline"
                      label="To"
                      color="amber accent-4"
                      v-model="invoice_number_to"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-text-outline "
                      label="Account Number"
                      color="amber accent-4"
                      v-model="account_number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-wallet-outline "
                      label="Air Waybill Number"
                      color="amber accent-4"
                      v-model="air_waybill"
                    ></v-text-field>
                  </v-flex>
                  <!-- ส่วนปุ่มการทำงาน -->
                  <v-flex xs12 lg12 text-right>
                    <!-- ปุ่มค้นหาข้อมูล -->
                    <v-btn
                      color="blue darken-3"
                      dark
                      class="ma-2"
                      @click="fn_getdataSAP"
                      ><b
                        ><v-icon class="mr-1">mdi-text-search</v-icon
                        >ค้นหาข้อมูล</b
                      ></v-btn
                    >
                    <!-- ปุ่มเคลียร์ข้อมูล -->
                    <v-btn
                      color="grey darken-2"
                      dark
                      outlined
                      class="ma-2"
                      @click="fn_clearData"
                      ><b
                        ><v-icon class="mr-1">mdi-notification-clear-all</v-icon
                        >เคลียร์ข้อมูล</b
                      ></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
        <!-- ส่วนของข้อมูลรวมทั้งหมด รายการภาษีหัก ณ ที่จ่าย -->
        <v-layout row wrap justify-end pr-14 pt-3 pb-4>
          <v-flex xs12 lg2 pa-4 align-self-center>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                Total
              </v-card-text>
              <v-card-text class="text-center">
                <h2 class="black--text">{{ total_data }}</h2>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-4>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- จำนวนเงินที่ต้องคำนวณภาษี -->
                Total basis amount
              </v-card-text>
              <v-card-text class="text-center">
                <h2 class="black--text">{{ total_basis_amount }}</h2>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- ส่วนของตารางข้อมูล SAP -->
        <v-layout row wrap justify-center align-center pb-5>
          <v-flex xs12 lg11 pl-4 pr-4>
            <v-card class="rounded-lg">
              <v-data-table
                :headers="headers"
                :items="desserts"
                hide-default-footer
                :items-per-page="perpage"
                :page.sync="page"
                @page-count="pageCount = $event"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="item.number">
                      <td class="text-center" style="font-size: 13px">
                        {{ index + 1 + perpage * (page - 1) }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        <div v-if="item.paid_date == null">
                          <span>-</span>
                        </div>
                        <div v-else-if="'paid_date' in item">
                          {{ getDate(item.paid_date.split("T")[0]) }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-center">
                        <div v-if="item.document_id == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.document_id }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.total_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.total_amount.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-center">
                        <v-btn
                          color="green accent-4"
                          dark
                          @click="fn_changeStatus(item), fn_resetDataEdit()"
                          >เปลี่ยนสถานะ</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <br />
              <v-pagination
                :total-visible="10"
                v-model="page"
                :length="pageCount"
                color="#f7b733"
                @input="fn_getdataSAP"
                circle
              ></v-pagination>
              <br />
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="dialog" max-width="600" scrollable>
          <v-card>
            <v-toolbar color="#FFCA05" dark>
              <v-layout row wrap justify-center>
                <v-flex xs6 lg10
                  ><span style="font-size:20px;" class="black--text"
                    >อัปเดตข้อมูล</span
                  >
                </v-flex>
                <v-flex xs6 lg1>
                  <v-icon
                    large
                    @click="(dialog = false), fn_resetDataEdit()"
                    color="black"
                    >mdi-close-circle</v-icon
                  ></v-flex
                >
              </v-layout></v-toolbar
            >

            <v-card-text class="pt-5" style="height: 570px;">
              <v-form ref="form" >
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs6 lg4 pa-1>
                    เลขที่เอกสาร
                  </v-flex>
                  <v-flex xs6 lg6 pa-1>
                    <v-text-field
                      v-model="document_id"
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs5 lg4 pa-1>
                    วันที่รับชำระ
                  </v-flex>
                  <v-flex xs5 lg6 pa-1>
                    <v-dialog
                      ref="dialogTodate"
                      v-model="modalPaiddate"
                      :return-value.sync="paid_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          color="indigo accent-4"
                          v-model="paid_date"
                          @click:prepend="modalPaiddate = true"
                          v-on="on"
                          disabled
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="paid_date"
                        scrollable
                        locale="th"
                        color="indigo accent-4"
                        @input="$refs.dialogTodate.save(paid_date)"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalPaiddate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs5 lg4 pa-1>
                    ชื่อลูกค้า
                  </v-flex>
                  <v-flex xs6 lg6 pa-1>
                    <v-text-field
                      :rules="[v => !!v || 'กรุณากรอก ชื่อลูกค้า']"
                      v-model="customerName"
                      type="text"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs5 lg4 pa-1>
                    มูลค่าที่นำมาคำนวณ ร้อยละ 1
                  </v-flex>
                  <v-flex xs6 lg4 pa-1>
                    <v-text-field
                      :rules="[
                        v => !!v || 'กรุณากรอก มูลค่าที่นำมาคำนวณร้อยละ 1'
                      ]"
                      v-model="wht1_basis"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1 lg2 pa-1>
                    บาท
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs5 lg4 pa-1>
                    ภาษีหัก ณ ที่จ่าย ร้อยละ 1
                  </v-flex>
                  <v-flex xs6 lg4 pa-1>
                    <v-text-field
                      :rules="[
                        v => !!v || 'กรุณากรอก ภาษีหัก ณ ที่จ่ายร้อยละ 1'
                      ]"
                      v-model="wht1"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1 lg2 pa-1>
                    บาท
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs5 lg4 pa-1>
                    มูลค่าที่นำมาคำนวณ ร้อยละ 3
                  </v-flex>
                  <v-flex xs6 lg4 pa-1>
                    <v-text-field
                      :rules="[
                        v => !!v || 'กรุณากรอก มูลค่าที่นำมาคำนวณร้อยละ 3'
                      ]"
                      v-model="wht3_basis"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1 lg2 pa-1>
                    บาท
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center align-content-center>
                  <v-flex xs5 lg4 pa-1>
                    ภาษีหัก ณ ที่จ่าย ร้อยละ 3
                  </v-flex>
                  <v-flex xs5 lg4 pa-1>
                    <v-text-field
                      :rules="[
                        v => !!v || 'กรุณากรอก ภาษีหัก ณ ที่จ่ายร้อยละ 3'
                      ]"
                      type="number"
                      v-model="wht3"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1 lg2 pa-1>
                    บาท
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="red darken-1"
                outlined
                @click="(dialog = false), fn_resetDataEdit()"
              >
                ยกเลิก
              </v-btn>
              <v-btn
                dark
                color="green accent-4"
                @click="fn_saveEditDataSAP()"
              >
                ตกลง
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
            <br />
          </v-card>
        </v-dialog>
        <!-- ส่วนเครื่องหมายสถานะ SAP -->
        <!-- <v-layout row wrap justify-center align-center pt-16 >
          <v-flex xs10 lg5>
            <v-layout row wrap>
              <v-flex xs1 lg1>
                <v-card-actions>
                  <v-icon size="30" color="black"
                    >mdi-file-document-edit-outline</v-icon
                  >
                </v-card-actions>
              </v-flex>
              <v-flex xs10 lg9>
                <v-card-text style="font-size:11px;">
                  ข้อมูลที่มีใน SAP แต่ไม่มีในระบบ IBS (ต้องกรอกเพิ่ม)
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs10 lg5>
            <v-layout row wrap>
              <v-flex xs1 lg1>
                <v-card-actions>
                  <v-icon size="30" color="red">mdi-help-circle-outline</v-icon>
                </v-card-actions>
              </v-flex>
              <v-flex xs10 lg9>
                <v-card-text style="font-size:11px;">
                  ข้อมูลไม่ตรงกับระบบ IBS และยังไม่ได้แก้ไข
                  (ตัวสีแดงคือข้อมูลที่ไม่ตรง)
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-center align-center >
          <v-flex xs10 lg5>
            <v-layout row wrap>
              <v-flex xs1 lg1>
                <v-card-actions>
                  <v-icon size="30" color="green"
                    >mdi-check-circle-outline</v-icon
                  >
                </v-card-actions>
              </v-flex>
              <v-flex xs10 lg9>
                <v-card-text style="font-size:11px;">
                  ข้อมูลตรงกับระบบ SAP
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs10 lg5>
            <v-layout row wrap>
              <v-flex xs1 lg1>
                <v-card-actions>
                  <v-icon size="30" color="indigo"
                    >mdi-text-box-check-outline</v-icon
                  >
                </v-card-actions>
              </v-flex>
              <v-flex xs10 lg9>
                <v-card-text style="font-size:11px;">
                  ข้อมูลไม่ตรงกับระบบ IBS แต่มีการแก้ไขแล้ว
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout> -->
        <!-- ส่วนของปุ่มสถานะกำลังดาวน์โหลด -->
        <v-overlay :value="loadingStatus">
          <div class="text-center">กำลังดาวน์โหลดข้อมูล ...</div>
          <v-progress-circular
            indeterminate
            size="64"
            class="ml-9 mt-3"
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Toolbar from '../components/toolbar'
import Swal2 from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { format } from 'date-fns'
const Toast = Swal2.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})
export default {
  components: {
    Toolbar
  },
  data: function () {
    return {
      dialogm1: '',
      dialogTest: false,
      account_number: '',
      invoice_number_from: '',
      invoice_number_to: '',
      air_waybill: '',
      document_id: '',
      paid_date: '',
      modalPaiddate: false,
      customerName: '',
      wht1: '',
      wht3: '',
      wht1_basis: '',
      wht3_basis: '',
      actual_amount: '',
      dialog: false,
      total_number: '',
      total_data: '',
      total_basis_amount: '',
      customer_name: '',
      customer_taxID: '',
      page: 1,
      perpage: 50,
      fromdate: '',
      modalFromdate: false,
      todate: '',
      modalTodate: false,
      loadingStatus: false,
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number',
          class: 'black--text'
        },
        {
          text: 'วันที่รับชำระ',
          value: 'paid_date',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'เลขที่อ้างอิง',
          value: 'document_id',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'จำนวนเงินที่รับชำระ',
          value: 'total_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'Update Status',
          value: 'status',
          sortable: false,
          align: 'center',
          class: 'black--text'
        }
      ],
      desserts: [],
      editData: []
    }
  },
  computed: {
    // ฟังก์ชันคำนวณจำนวนหน้าของ Table
    pageCount: {
      get () {
        const l = this.total_number
        const s = this.perpage
        return Math.ceil(l / s)
      },
      set (newName) {
        return newName
      }
    },
    _fromdate: {
      get () {
        if (this.fromdate === '' || this.fromdate === null) {
          return ''
        } else {
          return format(new Date(this.fromdate), 'yyyy-MM-dd')
        }
      }
    },
    _todate: {
      get () {
        if (this.todate === '' || this.todate === null) {
          return ''
        } else {
          return format(new Date(this.todate), 'yyyy-MM-dd')
        }
      }
    }
  },
  created () {
    // set defult ใน v-model
    // this.fromdate = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
    //   .toISOString()
    //   .substr(0, 10)
    // this.todate = new Date(Date.now()).toISOString().substr(0, 10)
  },
  mounted () {
    this.fn_getdataSAP()
  },
  methods: {
    fn_changeFromdate: function () {
      this.$refs.dialogFromdate.save(this.fromdate)
    },
    // ฟงก์ชัน format วันที่
    getDate: function (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // ฟังก์ชันค้นหาข้อมูล รายการรับชำระไม่พบข้อมูล
    fn_getdataSAP: function () {
      if (this.$refs.form_searchdata.validate()) {
        const payload = {
          customer_tax_id: this.customer_taxID,
          customername: this.customer_name,
          paid_date_from: this._fromdate,
          paid_date_to: this._todate,
          page: this.page,
          perpage: this.perpage,
          customer_account: this.account_number,
          document_id_from: this.invoice_number_from,
          document_id_to: this.invoice_number_to,
          awb_no: this.air_waybill
        }
        // console.log(payload)
        this.loadingStatus = true
        this.loadingStatus = true
        var AccessToken = JSON.parse(localStorage.DHL_Access).data.one_access_token
        var headers = { Authorization: '' + AccessToken }
        this.axios
          .post(process.env.VUE_APP_SERVICE_API + '/witholdingtax/sap', payload, { headers })
          .then(res => {
            if (res.data.status === 'OK' && res.data.data.length > 0) {
              this.desserts = res.data.data
              this.editData = res.data.data
              this.total_number = res.data.total_data
              // ค่าผลรวม Total
              if (res.data.total_data == null) {
                this.total_data = '-'
                console.log('Total Basis Amount : null')
              } else {
                this.total_data = parseInt(
                  res.data.total_data
                ).toLocaleString()
              }
              // ค่า Total basis amount
              if (res.data.total_basis_amount == null) {
                this.total_basis_amount = '-'
                console.log('Total Basis Amount : null')
              } else {
                this.total_basis_amount = res.data.total_basis_amount.toLocaleString(
                  'en-US',
                  { maximumFractionDigits: 2 }
                )
              }
              // console.log(this.desserts)
              this.loadingStatus = false
            } else if (res.data.data.length === 0) {
              this.loadingStatus = false
              Toast.fire({
                icon: 'warning',
                title: 'ไม่พบข้อมูลในระบบ'
              })
            }
          })
          .catch(err => {
            this.loadingStatus = false

            console.log(err)
            Toast.fire({
              icon: 'error',
              title: String(err)
            })
          })
      } else {
        this.desserts = []
        this.loadingStatus = false
        this.fn_clearData()
      }
      // this.fn_clearData()
    },
    // ฟังก์ชันเปลี่ยนสถานะ
    fn_changeStatus: function (item) {
      // console.log(item)

      this.document_id = item.document_id
      this.paid_date = item.paid_date.split('T')[0]
      this.customerName = item.customer_name
      this.wht1_basis = item.wht1_basis
      this.wht1 = item.wht1
      this.wht3_basis = item.wht3_basis
      this.wht3 = item.wht3
      this.dialog = true
      // console.log('edit Data :', this.editData)
    },
    // ฟังก์ชันบันทึกข้อมูลที่แก้ไข
    fn_saveEditDataSAP: function () {
      if (this.$refs.form.validate()) {
        this.dialog = true
        Swal2.fire({
          icon: 'warning',
          title: 'คุณต้องการบันทึกการเปลี่ยนแปลงหรือไม่ ?',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        }).then(result => {
          if (result.isConfirmed) {
            const payload = {
              document_id: this.document_id,
              customer_name: this.customerName,
              edit_wht_1: this.wht1,
              edit_wht_3: this.wht3,
              edit_wht_1_basis: this.wht1_basis,
              edit_wht_3_basis: this.wht3_basis
            }
            var AccessToken = JSON.parse(localStorage.DHL_Access).data.one_access_token
            var headers = { Authorization: '' + AccessToken }
            // console.log(payload)
            this.axios
              .post(
                process.env.VUE_APP_SERVICE_API +
                  '/witholdingtax/sap/v2/updatestatus',
                payload,
                { headers }
              )
              .then(res => {
                if (res.data.status === 'OK') {
                  this.editData = res.data.data
                  // console.log(this.editData)
                  Swal2.fire('บันทึกสำเร็จ', '', 'success')

                  this.dialog = false
                  this.fn_getdataSAP()
                } else {
                  this.dialog = false
                  Toast.fire({
                    icon: 'error',
                    title: res.data.errorMessage
                  })
                }
              })
              .catch(err => {
                this.dialog = false
                Toast.fire({
                  icon: 'error',
                  title: String(err)
                })
              })
          }
        })
      }
    },
    // ฟังก์ชันเคลียร์ข้อมูล
    fn_clearData: function () {
      this.account_number = ''
      this.invoice_number_from = ''
      this.invoice_number_to = ''
      this.air_waybill = ''
      this.total_number = ''
      this.total_data = ''
      this.total_basis_amount = ''
      this.total_actual_amount = ''
      this.total_wht1 = ''
      this.total_wht3 = ''
      this.fromdate = ''
      this.todate = ''
      this.customer_name = ''
      this.desserts = []
      this.editData = []
    },
    // ฟังก์ชันรีเซตค่า Validate
    fn_resetDataEdit: function () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
